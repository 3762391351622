import swimmingPool from "../../assets/comodidades/swimming-pool.svg";
import barbecue from "../../assets/comodidades/barbecue.svg";
import deckChair from "../../assets/comodidades/deck-chair.svg";
import energy from "../../assets/comodidades/energy.svg";
import fingerprint from "../../assets/comodidades/fingerprint.svg";
import gym from "../../assets/comodidades/gym.svg";
import sauna from "../../assets/comodidades/sauna.svg";
import securityGuard from "../../assets/comodidades/security-guard.svg";
import usb from "../../assets/comodidades/usb.svg";
import wheelchair from "../../assets/comodidades/wheelchair.svg";
import mountainBike from "../../assets/comodidades/mountain-bike.svg";
import churrasqueiraGoumert from "../../assets/comodidades/churrasqueira-gourmet.svg";
import circuitoSeguranca from "../../assets/comodidades/circuito-de-seguranca.svg";
import coworking from "../../assets/comodidades/coworking.svg";
import espacoGourmet from "../../assets/comodidades/espaco-gourmet.svg";
import espacoKids from "../../assets/comodidades/espaco-kids.svg";
import loungePub from "../../assets/comodidades/lounge-pub.svg";
import petPlace from "../../assets/comodidades/pet-place.svg";
import salaJogos from "../../assets/comodidades/sala-de-jogos-adulto.svg";
import energiaSolar from "../../assets/comodidades/sistema-de-energia-solar.svg";
import wifi from "../../assets/comodidades/wifi.svg";
import petCare from "../../assets/comodidades/pet-care.svg";
import salaoFestas from "../../assets/comodidades/salao-festas.svg";
import salaoJogoInfantis from "../../assets/comodidades/sala-de-jogo-infantis.svg";
import spa from "../../assets/comodidades/spa.svg";
import sacadaGourmet from "../../assets/comodidades/sacada-goumert.svg";
import salaoBeleza from "../../assets/comodidades/cones-salao-de-beleza.svg";
import sistemaAgua from "../../assets/comodidades/sistema-de-agua-da-chuva.svg";
import salaSpining from "../../assets/comodidades/sala-de-spinnig.svg";
import playground from "../../assets/comodidades/playground.svg";
import paredeVentilada from "../../assets/comodidades/parede-ventilada.svg";

export const comodidades = [
  {
    id: 1,
    comodidadeImg: swimmingPool,
    textSup: "Piscina",
    textSub: "com raia semiolímpica",
  },
  {
    id: 2,
    comodidadeImg: barbecue,
    textSup: "Churrasqueira Gourmet",
    textSub: "",
  },
  {
    id: Math.random(),
    comodidadeImg: mountainBike,
    textSup: "Bicicletário",
    textSub: "",
  },
  {
    id: 3,
    comodidadeImg: deckChair,
    textSup: "Deck Molhado",
    textSub: "",
  },
  {
    id: 5,
    comodidadeImg: fingerprint,
    textSup: "Fechadura Digital",
    textSub: "",
  },
  {
    id: Math.random(),
    comodidadeImg: securityGuard,
    textSup: "Guarita Blindada",
    textSub: "",
  },
  {
    id: 6,
    comodidadeImg: gym,
    textSup: "Fitness",
    textSub: "",
  },
  {
    id: 7,
    comodidadeImg: sauna,
    textSup: "Sauna ",
    textSub: "com vista panorâmica",
  },
  {
    id: Math.random(),
    comodidadeImg: usb,
    textSup: "Tomadas USB",
    textSub: "",
  },
  {
    id: Math.random(),
    comodidadeImg: wheelchair,
    textSup: "Elevador PCD",
    textSub: "para acesso à piscina",
  },
  {
    id: 4,
    comodidadeImg: energy,
    textSup: "Gerador Total",
    textSub: "",
  },
  {
    id: Math.random(),
    comodidadeImg: coworking,
    textSup: "Coworking",
    textSub: "",
  },
  {
    id: Math.random(),
    comodidadeImg: espacoGourmet,
    textSup: "Espaço Gourmet",
    textSub: "",
  },
  {
    id: Math.random(),
    comodidadeImg: loungePub,
    textSup: "Lounge Pub",
    textSub: "",
  },
  {
    id: Math.random(),
    comodidadeImg: petCare,
    textSup: "Pet Care",
    textSub: "",
  },
  {
    id: Math.random(),
    comodidadeImg: petPlace,
    textSup: "Pet Place",
    textSub: "",
  },
  {
    id: Math.random(),
    comodidadeImg: salaoFestas,
    textSup: "Salão de Festas",
    textSub: "",
  },
  {
    id: Math.random(),
    comodidadeImg: salaoJogoInfantis,
    textSup: "Salão de Jogos",
    textSub: "Infantil",
  },
  {
    id: Math.random(),
    comodidadeImg: salaJogos,
    textSup: "Salão de Jogos",
    textSub: "Adulto",
  },
  {
    id: Math.random(),
    comodidadeImg: salaSpining,
    textSup: "Spinning",
    textSub: "",
  },
  {
    id: Math.random(),
    comodidadeImg: spa,
    textSup: "Spa",
    textSub: "",
  },
  {
    id: Math.random(),
    comodidadeImg: salaoBeleza,
    textSup: "Salão de Beleza",
    textSub: "",
  },
  {
    id: Math.random(),
    comodidadeImg: playground,
    textSup: "Playground",
    textSub: "",
  },
  {
    id: Math.random(),
    comodidadeImg: sistemaAgua,
    textSup: "Sistema de reutilização da água da chuva",
    textSub: "",
  },
  // {
  //   id: Math.random(),
  //   comodidadeImg: circuitoSeguranca,
  //   textSup: "Circuito de Segurança",
  //   textSub: "Alta Tecnologia",
  // },
  {
    id: Math.random(),
    comodidadeImg: wifi,
    textSup: "Wifi nas Áreas Sociais",
    textSub: "",
  },
  {
    id: Math.random(),
    comodidadeImg: energiaSolar,
    textSup: "Sistema de Captação de Energia Solar",
    textSub: "",
  },
  {
    id: Math.random(),
    comodidadeImg: espacoKids,
    textSup: "Espaço Kids",
    textSub: "",
  },
  // {
  //   id: Math.random(),
  //   comodidadeImg: paredeVentilada,
  //   textSup: "Parede Ventilada",
  //   textSub: "",
  // },
  // {
  //   id: Math.random(),
  //   comodidadeImg: sacadaGourmet,
  //   textSup: "Sacada gourmet",
  //   textSub: "",
  // },
];
