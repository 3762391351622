import { Comodidade } from "../Comodidade";
import { HeaderTitle } from "../HeaderTitle";
import { MainContainer } from "../MainContainer";
import { ComodidadeContainer, Container } from "./styles";
import { comodidades } from "./comodidadesData";
import Delayed from "../helpers/Delayed";
import { SwiperComodidade } from "../SwiperComodidade";

export function SectionComodidades() {
  return (
    <MainContainer>
      <Container>
        <HeaderTitle
          title="Comodidades"
          description="A arte de viver bem acolhe com elegância. Para dentro de cada espaço do Lux Design Living, fizemos questão de levar não só o máximo de sofisticação e modernidade, mas também o contato direto com a natureza e a busca por uma vida mais saudável."
          descriptionAlign="start"
          titleAlign="start"
        />
        <ComodidadeContainer>
          <SwiperComodidade />
        </ComodidadeContainer>
      </Container>
    </MainContainer>
  );
}
