import gradeSvg from "../../assets/elements/grade.svg";

import { HeaderTitle } from "../HeaderTitle";
import { Container, FeatureContent, FooterContent } from "./styles";

import quadraTechImg from "../../assets/logos/quadra-tech.png";
import imgVideo from "../../assets/capa-video.png";
import ScrollAnimation from "react-animate-on-scroll";
import { PlayVideo } from "../PlayVideo";
import videoModal from "../../assets/videos/video.mp4";

export function SectionConforto() {
  return (
    <Container>
      <img src={gradeSvg} alt="grade vertical" className="grade-vertical" />

      <HeaderTitle
        title="Conforto"
        description="Um projeto único para você ter mais tempo e momentos de qualidade dentro e fora de casa."
        titleAlign="center"
        descriptionAlign="center"
      />
      <div className="row row-cols-md-3 justify-content-center">
        <FeatureContent>
          <ScrollAnimation animateIn="animate__fadeInLeft">
            <h3 className="gold-item">
              2<span>e</span>3
            </h3>
            <div className="content">
              <h3>Suítes</h3>
              <h3>149 a 170m²</h3>
            </div>
          </ScrollAnimation>
        </FeatureContent>
        <FeatureContent isMid={true}>
          <ScrollAnimation animateIn="animate__fadeInDown">
            <h3 className="gold-item">2</h3>
            <div className="content">
              <h3>Vagas de</h3>
              <h3>Garagem</h3>
            </div>
          </ScrollAnimation>
        </FeatureContent>

        <FeatureContent>
          <ScrollAnimation animateIn="animate__fadeInRight">
            <h3 className="gold-item">4</h3>
            <div className="content">
              <h3>Coberturas duplex</h3>
              <h3>297,50m² a 335,86m²</h3>
            </div>
          </ScrollAnimation>
        </FeatureContent>
      </div>

      <FooterContent>
        <div>
          <h3>Tecnologia com selo</h3>
          <img src={quadraTechImg} alt="Quadra Tech" />
        </div>
        <ScrollAnimation animateIn="animate__fadeInUp">
          <p>
            Buscamos o que existe de mais moderno para você morar bem. Não é
            apenas automação. É inteligência para oferecer o máximo de
            segurança, facilidade e a qualidade de vida que você merece.
          </p>
        </ScrollAnimation>
      </FooterContent>

      <br />
      <br />
      <h2 className="text-gold text-thin text-center text-uppercase">
        {" "}
        ASSISTA AO VÍDEO E SAIBA MAIS SOBRE O EMPREENDIMENTO{" "}
      </h2>
      <br />

      <PlayVideo
        imagemCapa={imgVideo}
        video={videoModal}
        idModal="videoConcept"
        textoAlt="Vídeo Quadra Concept"
      />
    </Container>
  );
}
