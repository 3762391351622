import { Companies } from './components/Companies';
import { Header } from './components/Header';
import { Main } from './components/Main';
import { GlobalStyle } from './styles/global';
import 'animate.css/animate.min.css';
import { Footer } from './components/Footer';
import { PopUp } from './components/Popup';

export default function App() {
  return (
    <>
      <Header />
      <Main />
      <Companies />
      <Footer />
      <GlobalStyle />
      {/*   <PopUp /> */}
    </>
  );
}
